import ReactDOM from "react-dom"

import { Provider } from "react-redux"
import { BrowserRouter, Route, Switch } from "react-router-dom"

import "./styles/index.scss"

// Import custom MUI theme
import theme from "@mobilemind/common/src/theme/theme"
import { ThemeProvider } from "@mui/material/styles"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"

import App from "./components/App"
import CertificateShareLayout from "./components/CertificateShareLayout"
import LearnLayout from "./components/LearnLayout"
import LoginLayout from "./components/LoginLayout"
import CourseLayout from "./features/activeCourse/CourseLayout"

import BadgeCertificateLayout from "./features/badges/BadgeCertificateLayout"
import EventCertificateLayout from "./features/events/EventCertificateLayout"
import InProgressLayout from "./features/inProgress/InProgressLayout"
import LeaderboardsLayout from "./features/leaderboards/LeaderboardsLayout"
import LearningPathCertificateLayout from "./features/learningPaths/LearningPathCertificateLayout"
import LearningPathLayout from "./features/learningPaths/LearningPathLayout"
import { MandatedTrainingLayout } from "./features/mandatedTraining/MandatedTrainingLayout"
import ProfileLayout from "./features/profile/ProfileLayout"
import SubmitTicket from "./components/SubmitTicket"

import EventSingleLayout from "./features/events/eventSingle/EventSingleLayout"
import RewindLayout from "./features/rewind/RewindLayout"

import {
  getToken,
  getUser,
  getUserId,
} from "@mobilemind/common/src/actions/user"

import { isChrome, isEdge, isMobile } from "react-device-detect"
import { sendMessage } from "./actions"

import ExternalApprovalsLayout from "./features/backpack/ExternalApprovalsLayout"
import EventsContainer from "./features/events/EventsContainer"
import store from "./store/store"

import BackpackContainer from "./features/backpack/BackpackContainer"
import BackpackPrintLayout from "./features/backpack/BackpackPrintLayout"

import CoursesCompletedLayout from "./features/backpack/CoursesCompletedLayout"
import EventsAttendedLayout from "./features/backpack/EventsAttendedLayout"
import BadgesLayout from "./features/badges/BadgesLayout"
import LearningPathsLayout from "./features/learningPaths/LearningPathsLayout"
import { MandatedTrainingCertificateLayout } from "./features/mandatedTraining/MandatedTrainingCertificateLayout"

import { PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { msalConfig } from "./graphAuthConfig"

// Set the tab title and favicon
document.title = theme.title
const link = document.createElement("link")
link.rel = "shortcut icon"
link.href = window.location.hostname + ".ico"
document.head.appendChild(link)

const canSendMessage = !isMobile && (isChrome || isEdge)
const msalInstance = new PublicClientApplication(msalConfig)

store.dispatch(getUserId()).then((response) => {
  // If the user is logged in, render the app
  if (response) {
    // Try to get the extension version if the user is using Chrome, and pass along the current user's session
    if (canSendMessage) {
      store.dispatch(sendMessage({ type: "getVersion" }))
      let payload = {
        userId: response.payload.userId,
        group: response.payload.group,
        loggedIn: response.payload.loggedIn,
        orgRoles: response.payload.orgRoles,
        roles: response.payload.roles,
        subgroup: response.payload.subgroup,
        collection: response.payload.collection,
        subgroups: response.payload.subgroups,
        trial: response.payload.trial,
      }
      store.dispatch(
        sendMessage({
          type: "getUserId",
          payload,
        })
      )
    }

    store
      .dispatch(getUser(store.getState().session.userId))
      .then((response) => {
        // Send latest user info to extension
        canSendMessage &&
          store.dispatch(
            sendMessage({ type: "userIsLoggedIn", payload: response })
          )

        // We have to do this now for PATCH requests, see
        // https://drupal.stackexchange.com/questions/230159/message-x-csrf-token-request-header-is-missing/230238

        store.dispatch(getToken()).then((response) => {
          canSendMessage &&
            store.dispatch(
              sendMessage({ type: "getToken", payload: response.payload })
            )

          ReactDOM.render(
            <ThemeProvider theme={theme}>
              <MsalProvider instance={msalInstance}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Provider store={store}>
                    <BrowserRouter>
                      <App>
                        <Switch>
                          <Route
                            path="/"
                            component={LearnLayout}
                            exact={true}
                          />
                          <Route path="/profile" component={ProfileLayout} />
                          <Route
                            exact={true}
                            path="/learning-path/:pathId"
                            component={LearningPathLayout}
                          />
                          <Route
                            exact={true}
                            path="/mandated-training/:trainingId"
                            component={MandatedTrainingLayout}
                          />
                          <Route
                            exact={true}
                            path="/course/:courseId/:learningPathId?"
                            component={CourseLayout}
                          />
                          <Route
                            path="/in-progress"
                            component={InProgressLayout}
                          />

                          <Route
                            exact={true}
                            path="/backpack"
                            component={BackpackContainer}
                          />
                          <Route
                            exact={true}
                            path="/backpack/courses"
                            component={CoursesCompletedLayout}
                          />
                          <Route
                            exact={true}
                            path="/backpack/events"
                            component={EventsAttendedLayout}
                          />
                          <Route
                            exact={true}
                            path="/backpack/external"
                            component={ExternalApprovalsLayout}
                          />
                          <Route
                            exact={true}
                            path="/backpack/badges"
                            component={BadgesLayout}
                          />
                          <Route
                            exact={true}
                            path="/backpack/learning-paths"
                            component={LearningPathsLayout}
                          />

                          <Route
                            exact={true}
                            path="/backpack/print"
                            component={BackpackPrintLayout}
                          />
                          <Route
                            exact={true}
                            path="/leaderboards"
                            component={LeaderboardsLayout}
                          />
                          <Route
                            exact={true}
                            path="/certificate/badge/:userBadgeId"
                            component={BadgeCertificateLayout}
                          />
                          <Route
                            exact={true}
                            path="/certificate/mandated-training/:trainingId"
                            component={MandatedTrainingCertificateLayout}
                          />
                          <Route
                            exact={true}
                            path="/certificate/learning-path/:certificateId"
                            component={LearningPathCertificateLayout}
                          />
                          <Route
                            exact={true}
                            path="/certificate/event/:eventId"
                            component={EventCertificateLayout}
                          />
                          <Route
                            exact={true}
                            path="/certificate-share/:type/:certificateId"
                            component={CertificateShareLayout}
                          />
                          <Route
                            exact={true}
                            path="/rewind/:uuid"
                            component={RewindLayout}
                          />
                          <Route
                            exact={true}
                            path="/events"
                            component={EventsContainer}
                          />

                          <Route
                            exact={true}
                            path="/events/event/:eventId/:conferenceId?"
                            component={EventSingleLayout}
                          />
                          <Route
                            exact={true}
                            path="/events/conference/:eventId"
                            component={EventSingleLayout}
                          />
                          <Route
                            exact={true}
                            path="/events/observation/:eventId"
                            component={EventSingleLayout}
                          />
                          <Route
                            exact={true}
                            path="/submit-ticket"
                            component={SubmitTicket}
                          />
                        </Switch>
                      </App>
                    </BrowserRouter>
                  </Provider>
                </LocalizationProvider>
              </MsalProvider>
            </ThemeProvider>,
            document.getElementById("root")
          )
        })
      })
  }
  // Or if we're at a publicly shareable certificate page
  else if (window.location.href.includes("certificate-share")) {
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Provider store={store}>
            <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
              <Route
                exact={true}
                path="/certificate-share/:type/:certificateId"
                component={CertificateShareLayout}
              />
            </BrowserRouter>
          </Provider>
        </LocalizationProvider>
      </ThemeProvider>,
      document.getElementById("root")
    )
    // Or if we're at a publicly shareable rewind page
  } else if (window.location.href.includes("rewind")) {
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Provider store={store}>
            <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
              <Route
                exact={true}
                path="/rewind/:uuid"
                component={RewindLayout}
              />
            </BrowserRouter>
          </Provider>
        </LocalizationProvider>
      </ThemeProvider>,
      document.getElementById("root")
    )
    // Otherwise render the login page
  } else {
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Provider store={store}>
            <LoginLayout />
          </Provider>
        </LocalizationProvider>
      </ThemeProvider>,
      document.getElementById("root")
    )
  }
})
