import { Component } from "react"
import { connect } from "react-redux"
import "../styles/certificate.scss"
import { getCertificate } from "../actions"
import BadgeCertificate from "../features/badges/BadgeCertificate"
import theme from "@mobilemind/common/src/theme/theme"
import { withRouter } from "react-router-dom"
import EventCertificate from "@mobilemind/common/src/components/backpack/certificate/EventCertificate"
import { LearningPathCertificatePreview } from "@mobilemind/common/src/components/LearningPathCertificatePreview"
import Loading from "@mobilemind/common/src/components/Loading"

const mapStateToProps = ({ session }, ownProps) => {
  return {
    certificateId: ownProps.match.params.certificateId,
    type: ownProps.match.params.type,
    session,
    certificates: session.certificates.data,
  }
}

const mapDispatchToProps = {
  getCertificate,
}

class CertificateShareLayout extends Component {
  async componentDidMount() {
    const { certificateId, type } = this.props
    this.props.getCertificate(certificateId, type.replace("-", "_"))
  }

  render() {
    const fetchedCertificate = this.props.certificates[0]

    const learningPathCertificateProps = {
      isSharePrint: true,
      certificate: this.props.certificates[0],
      user: this.props.session.user,
      theme,
      session: this.props.session,
      teacherName: fetchedCertificate?.attributes.field_teacher_name,
      pathName: fetchedCertificate?.attributes.name,
      completedDate: fetchedCertificate?.learning_path_completed,
      imageSrc:
        process.env.REACT_APP_API_URL +
        fetchedCertificate?.attributes.field_image_url,
      estimatedTime: Number(
        fetchedCertificate?.attributes.field_total_time_spent
      ),
      pathCategories: fetchedCertificate?.timeSpent?.map((cat) => {
        return {
          name: cat.attributes.field_category_label,
          timeSpent: cat.attributes.field_time_spent,
          imageSrc:
            process.env.REACT_APP_API_URL + cat.attributes.field_category_image,
          id: cat.id,
        }
      }),
      group: {
        label: [
          {
            value: "Organization",
          },
        ],
        field_signature_name: [
          {
            value: fetchedCertificate?.attributes.field_signature_name,
          },
        ],
        field_signature_font: [
          {
            value: fetchedCertificate?.attributes.field_signature_font,
          },
        ],
        field_signature_title: [
          {
            value: fetchedCertificate?.attributes.field_signature_title,
          },
        ],
        field_logo_path: [
          {
            value: fetchedCertificate?.attributes.field_logo_path,
          },
        ],
      },
      certificateSettings: {
        signature:
          fetchedCertificate?.attributes.field_enabled_certificate_values.includes(
            "signature"
          ),
        orgLogo:
          fetchedCertificate?.attributes.field_enabled_certificate_values.includes(
            "org_logo"
          ),
        categories:
          fetchedCertificate?.attributes.field_enabled_certificate_values.includes(
            "categories"
          ),
        pdCredit:
          fetchedCertificate?.attributes.field_enabled_certificate_values.includes(
            "pd_credit"
          ),
      },
    }

    const props = {
      certificate: this.props.certificates[0],
      user: this.props.session.user,
      theme,
      session: this.props.session,
    }
    if (this.props.certificates.length > 0) {
      return (
        <>
          {this.props.type === "event" && (
            <div className="eventCertificatePrintContainer">
              <EventCertificate {...props} />
            </div>
          )}

          {this.props.type === "badge" && <BadgeCertificate {...props} />}

          {this.props.type === "learning-path" &&
            this.props.certificates[0] && (
              <div className="learningPathCertificatePrintContainer">
                <LearningPathCertificatePreview
                  {...learningPathCertificateProps}
                />
              </div>
            )}
        </>
      )
    }
    return <Loading fullPage={true} message="Generating certificate..." />
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CertificateShareLayout)
)
