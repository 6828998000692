import { Component } from "react"
import { connect } from "react-redux"
import classNames from "classnames"

import { Grid, Card, Button } from "@mui/material"
import BackArrow from "../../icons/BackArrow"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"

import moment from "moment"
import CertificateMenu from "@mobilemind/common/src/components/backpack/certificate/CertificateMenu"
import ExportLink from "../../components/ExportLink"

import { isMobile } from "react-device-detect"
import { getCompletedLearningPaths } from "../achievements/achievementsSlice"

import {
  generateTimestamp,
  generateTrackWidth,
  calculateGoalPace,
} from "@mobilemind/common/src/functions/index"
import Loading from "@mobilemind/common/src/components/Loading"
import ProgressBar from "@mobilemind/common/src/components/ProgressBar"

import { getUserLearningPaths } from "@mobilemind/common/src/actions/course"
import pluralize from "pluralize"
import { getLearningPathTypes, increaseCurrentPage } from "./learningPathsSlice"
import BottomScrollListener from "react-bottom-scroll-listener"

import theme from "@mobilemind/common/src/theme/theme"

import "../../styles/certificate.scss"
import Padlock from "../../icons/Padlock"
import LearningPathFilters from "./LearningPathFilters"

const mapStateToProps = ({
  achievements,
  session,
  assessments,
  navbar,
  learningPaths,
  categories,
  courses,
  goals,
  sidebar,
}) => {
  return {
    achievements,
    learningPaths,
    assessments,
    courses,
    navbar,
    sidebar,
    categories,
    session,
    goals,
  }
}

const mapDispatchToProps = {
  getUserLearningPaths,
  getLearningPathTypes,
  increaseCurrentPage,
  getCompletedLearningPaths,
}

function LearningPathsLayout(props) {
  let newProps = { ...props }

  if (window.location.href.includes("backpack")) {
    newProps.learningPaths = { ...props.achievements.learningPaths }
    newProps.learningPaths.types = props.achievements.learningPaths.types
  } else {
    newProps.learningPaths = { ...props.learningPaths }
    newProps.learningPaths.types = props.learningPaths.types
  }

  return <LearningPathsContainer {...newProps} theme={theme} />
}

class LearningPathsContainer extends Component {
  componentDidMount = async () => {
    window.scrollTo(0, 0)
    if (
      this.props.learningPaths.types &&
      !this.props.learningPaths.types.fetched
    ) {
      this.props.getLearningPathTypes()
    }
    if (this.props.learningPaths.achievements) {
      this.props.getCompletedLearningPaths()
    }
  }

  handleScroll = () => {
    const { learningPaths } = this.props
    // If we aren't already fetching more results
    if (!learningPaths.isFetching && !learningPaths.isFetchingMore) {
      // And if there are more pages to fetch
      if (learningPaths.currentPage < learningPaths.totalPages - 1) {
        this.props.increaseCurrentPage()
      }
    }
  }

  render() {
    const { learningPaths, navbar, session, theme } = this.props
    const exportURL =
      process.env.REACT_APP_API_URL + "/learning-path-report/user"

    return (
      <div
        className={classNames(
          "page learning-path-library",
          !learningPaths.hasFetched && "loading",
          window.location.href.includes("backpack") && "backpack"
        )}
      >
        {window.location.href.includes("backpack") && (
          <div id="backpack-sidebar">
            <Button
              className="backButton"
              onClick={() => this.props.history.push("/backpack")}
            >
              <BackArrow />
              <span
                style={{ marginLeft: 10, color: theme.palette.primary.main }}
                className="buttonText"
              >
                Back
              </span>
            </Button>
            <LearningPathFilters />
          </div>
        )}

        <BottomScrollListener onBottom={this.handleScroll}>
          <div className={classNames("itemCount", isMobile && "mobile")}>
            {learningPaths.hasFetched && (
              <>
                <span style={{ color: theme.palette.primary.main }}>
                  {learningPaths.totalRecords}
                </span>
                {navbar.activeItem === "backpack" ? (
                  <>
                    Learning {pluralize("Path", learningPaths.data.length)}{" "}
                    Completed
                  </>
                ) : (
                  <>Learning {pluralize("Path", learningPaths.data.length)}</>
                )}
              </>
            )}
          </div>

          {navbar.activeItem === "backpack" && (
            <ExportLink exportURL={exportURL} />
          )}

          <Grid container spacing={3}>
            {learningPaths.data.map((path, index) => {
              let coursesCompleted, goalDeadline, goalOverdue, daysTilDue

              const hasGoal = path.lp_goal_info && path.lp_goal_info[0]

              if (hasGoal) {
                goalDeadline = moment(
                  path.lp_goal_info[0].lp_goal_due_date_value
                )
                goalOverdue = hasGoal && goalDeadline.isBefore(moment())
                daysTilDue = hasGoal && goalDeadline.diff(moment(), "days")
              }

              const completedCount = path.field_user_lp_completed_courses
                ? Number(path.field_user_lp_completed_courses)
                : 0

              const onTrack =
                goalOverdue &&
                (!generateTrackWidth(path) ||
                  Number(generateTrackWidth(path).replace("%", "")) >
                    (completedCount / path.field_num_courses) * 100)
                  ? false
                  : true

              const isUnstarted = !path.field_user_lp_status
              const isInProgress = path.field_user_lp_status === "in_progress"
              const isCompleted = path.field_user_lp_status === "completed"
              const isLocked = path.field_lp_prereq_completed === false

              let targetLP = path.field_lp_id
              if (isLocked) {
                targetLP = path.field_prerequisite_learning_path_target_id
              }

              if (!window.location.href.includes("backpack") || isCompleted) {
                return (
                  <Card
                    key={path.id + "-" + index}
                    className={classNames(
                      "widget learningPath",
                      isCompleted && "completed",
                      isInProgress && "inProgress"
                    )}
                    style={{
                      animationDelay:
                        (index - learningPaths.currentPage * 25) * 0.05 + "s",
                      pointerEvents: isLocked ? "none" : "all",
                    }}
                  >
                    <Link to={"learning-path/" + targetLP}>
                      {isLocked && (
                        <div className="locked-message">
                          <div
                            style={{
                              backgroundColor: theme.palette.primary.main,
                              opacity: 0.5,
                              position: "absolute",
                              top: 0,
                              bottom: 0,
                              right: 0,
                              left: 0,
                              zIndex: 2,
                            }}
                          />
                          <div style={{ zIndex: 4 }}>
                            <span className="icon lock">
                              <Padlock />
                            </span>
                            <p>
                              Complete{" "}
                              <strong>{path.field_prerequisite_lp_name}</strong>{" "}
                              to unlock this Learning Path!
                            </p>
                          </div>
                        </div>
                      )}

                      <div className="topContent">
                        <div className="capsule">
                          <div>{path.field_lp_type}</div>
                        </div>
                        <div
                          className="pathImage"
                          style={{
                            backgroundImage:
                              'url("' + path.field_lp_image_uri + '")',
                          }}
                        />
                      </div>

                      {path.lp_goal_info &&
                        path.lp_goal_info[0] &&
                        !isCompleted && (
                          <div className="goalBanner">
                            <div className="goalBadge icon goal" />
                            {!goalOverdue ? (
                              <>
                                {daysTilDue === 0 ? (
                                  <span>Due Today</span>
                                ) : (
                                  <span>
                                    {daysTilDue} {pluralize("Days", daysTilDue)}{" "}
                                    Left
                                  </span>
                                )}
                              </>
                            ) : (
                              <>{!isCompleted && <span>Overdue</span>}</>
                            )}
                          </div>
                        )}

                      {path.lp_goal_info &&
                        path.lp_goal_info[0] &&
                        isCompleted && (
                          <div
                            className="goalBanner"
                            style={{ left: 15, right: "auto" }}
                          >
                            <div className="goalBadge icon goal" />
                            <span>Goal Reached</span>
                          </div>
                        )}

                      <div
                        className={classNames(
                          "bottomContent",
                          hasGoal && "goal",
                          isUnstarted && "unstarted",
                          isInProgress && "inProgress"
                        )}
                        style={{
                          borderColor: isCompleted
                            ? "#24DC91"
                            : theme.palette.primary.main,
                          borderTopWidth: 0,
                        }}
                      >
                        <h2>{path.name}</h2>

                        {isInProgress && !hasGoal && !isCompleted && (
                          <span className="status-icon icon laptop"></span>
                        )}
                        {isCompleted && (
                          <span className="status-icon icon check"></span>
                        )}

                        {isInProgress && !hasGoal && (
                          <div className="timestamp flexRow">
                            <span>
                              {isCompleted && (
                                <>
                                  Completed{" "}
                                  {generateTimestamp(
                                    path.field_user_lp_updated
                                  )}
                                </>
                              )}
                              {!isCompleted && coursesCompleted > 0 && (
                                <>
                                  In Progress{" "}
                                  {generateTimestamp(
                                    path.field_user_lp_updated
                                  )}
                                </>
                              )}
                              {!isCompleted && !coursesCompleted && (
                                <>
                                  Started{" "}
                                  {generateTimestamp(
                                    path.field_user_lp_updated
                                  )}
                                </>
                              )}
                            </span>
                          </div>
                        )}

                        {isCompleted && (
                          <div className="timestamp flexRow">
                            <span className="icon check"></span>
                            <>
                              Completed{" "}
                              {generateTimestamp(path.field_user_lp_updated)}
                            </>
                          </div>
                        )}
                        {hasGoal && !isCompleted && path.field_user_lp_uuid && (
                          <>
                            <div className="flexRow status">
                              <div className="speedometer">
                                <span
                                  className={classNames(
                                    "needle",
                                    onTrack && "onTrack"
                                  )}
                                />
                              </div>
                              {onTrack ? (
                                <>You're on track!</>
                              ) : (
                                <>You're not on track to complete this goal.</>
                              )}
                            </div>
                            <ul className="stats">
                              <li>
                                <span>On Track Pace</span>{" "}
                                <strong
                                  style={{ color: theme.palette.primary.main }}
                                >
                                  {calculateGoalPace(path).pace} /{" "}
                                  {calculateGoalPace(path).unit}
                                </strong>{" "}
                              </li>
                            </ul>
                          </>
                        )}

                        {isUnstarted && !isLocked && (
                          <>
                            <p>
                              {path.field_description_value?.substring(0, 180)}
                              {path.field_description_value?.length > 180 && (
                                <>...</>
                              )}
                            </p>
                            {!hasGoal && completedCount > 0 && (
                              <span className="already">
                                You've already completed{" "}
                                <strong>{completedCount}</strong> of these
                                courses!
                              </span>
                            )}
                          </>
                        )}

                        {path.field_user_lp_uuid &&
                          !isCompleted &&
                          !hasGoal && (
                            <div className="pathStatus">
                              <div
                                className="count"
                                style={{ marginBottom: -15 }}
                              >
                                {completedCount} / {path.field_num_courses}{" "}
                                Courses Completed
                              </div>
                              <ProgressBar
                                total={
                                  Array.from(
                                    Array(path.field_num_courses).keys()
                                  ).length
                                }
                                completed={completedCount}
                                width={
                                  (completedCount / path.field_num_courses) *
                                  100
                                }
                              />
                            </div>
                          )}

                        {hasGoal && !isCompleted && (
                          <>
                            <div className="count">
                              {completedCount} / {path.field_num_courses}{" "}
                              Courses Completed
                            </div>
                            <div className="progressBarGoal">
                              <div
                                className="goalProgress"
                                style={{
                                  width:
                                    (completedCount / path.field_num_courses) *
                                      100 +
                                    "%",
                                  backgroundColor: theme.palette.secondary.main,
                                }}
                              ></div>
                              <div
                                className="track"
                                style={{
                                  width: generateTrackWidth(path),
                                  backgroundColor: theme.palette.primary.main,
                                }}
                              ></div>
                            </div>
                          </>
                        )}
                      </div>
                    </Link>
                    <footer
                      className="flexRow"
                      style={{ justifyContent: "center" }}
                    >
                      {isCompleted && (
                        <CertificateMenu
                          session={session}
                          user={session.user}
                          label={"View Certificate"}
                          learningPath={path}
                        />
                      )}
                    </footer>
                  </Card>
                )
              }
              return null
            })}
          </Grid>

          {!learningPaths.data.length && !navbar.activeItem === "backpack" && (
            <p>
              Whoops! It looks like no Learning Paths matched your criteria.
              Adjust your filters and try again.
            </p>
          )}

          {(learningPaths.isFetching || learningPaths.isFetchingMore) && (
            <header className="pageLoader">
              <Loading
                color={theme.palette.secondary.main}
                message="Getting learning paths..."
              />
            </header>
          )}
        </BottomScrollListener>
      </div>
    )
  }
}

LearningPathsLayout.propTypes = {
  categories: PropTypes.object,
  courses: PropTypes.object,
  filters: PropTypes.object,
  filteredLearningPaths: PropTypes.array,
  learningPaths: PropTypes.object,
  navbar: PropTypes.object,
  session: PropTypes.object,
  sidebar: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(LearningPathsLayout)
