import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState, useAppDispatch } from "../../../store/store"

import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  TextField,
} from "@mui/material"
import theme from "@mobilemind/common/src/theme/theme"
import "../../../styles/external-pd.scss"

import {
  addAttachment,
  addTimeBlock,
  deleteAttachment,
  deleteTimeBlock,
  fetchExternalPDRequests,
  getPreApprovalForms,
  saveExternalRequest,
  saveUserPreApprovalForm,
  selectPreApprovalForm,
  setPreApprovalNeeded,
  updatePDRequest,
  updateTimeBlock,
} from "../../../store/reducers/externalPD"

import Loading from "@mobilemind/common/src/components/Loading"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"

import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import FileUpload from "@mobilemind/common/src/components/FileUpload"
import ProgressBar from "@mobilemind/common/src/components/ProgressBar"
import classNames from "classnames"
import DateRangeSelector from "@mobilemind/common/src/components/DateRangeSelector"

import LearningBuddyMessage from "@mobilemind/common/src/components/LearningBuddyMessage"
import pluralize from "pluralize"

import CategorySelect from "@mobilemind/common/src/components/CategorySelect"
import Plus from "@mobilemind/common/src/icons/Plus"
import RatingStar from "@mobilemind/common/src/icons/RatingStar"
import ButtonSmall from "../../../components/ButtonSmall"
import DeleteButton from "../../../components/DeleteButton"
import UnapprovedCard from "./UnapprovedCard"

import TimeCapsule from "@mobilemind/common/src/components/TimeCapsule"

import moment from "moment"
import { PreApprovalFormEntity } from "../types"
import { PreApprovalForm } from "./PreApprovalForm"
import InlineBuddyMessage from "../../learningBuddy/InlineBuddyMessage"

interface ModalProps {
  open: boolean
  close: () => void
}

type BlockType = {
  categoryId: string
  hours: number
  id: string
  minutes: number
}

const RequestCreditModal: React.FC<ModalProps> = ({ open, close }) => {
  const [currentStep, setCurrentStep] = useState(1)
  const [timeLimitReached, setTimeLimitReached] = useState(false)
  const [isSubmittingPreApproval, setIsSubmittingPreApproval] = useState(false)

  const dispatch = useAppDispatch()

  const { currentRequest, recommended, preApprovalForms } = useSelector(
    (state: RootState) => state.externalPD
  )

  const selectedPreApprovalFormId =
    currentRequest.preApprovalFormId ?? preApprovalForms.data[0]?.id

  const selectedPreApprovalForm = preApprovalForms.data.find(
    (form: PreApprovalFormEntity) => form.id === selectedPreApprovalFormId
  )

  const [responsesToSubmit, setResponsesToSubmit] = useState<any[]>([])

  useEffect(() => {
    if (!preApprovalForms.fetched) {
      dispatch(getPreApprovalForms({}))
    }
  }, [preApprovalForms.fetched, dispatch])

  const { preApprovalNeeded } = currentRequest

  const { calendar } = useSelector((state: RootState) => state)
  const { categories } = useSelector((state: RootState) => state)

  let sourceEvent, isSameDayEvent
  const isRecommended: boolean = currentRequest.recommendedId ? true : false

  if (isRecommended) {
    sourceEvent = recommended.data.find(
      (event: any) => event.uuid === currentRequest.recommendedId
    )
      ? recommended.data.find(
          (event: any) => event.uuid === currentRequest.recommendedId
        )
      : calendar.recommendedEvents.data.find(
          (event: any) => event.uuid === currentRequest.recommendedId
        )

    isSameDayEvent =
      sourceEvent &&
      moment(sourceEvent.field_start_date_value).format("MMMM Do") ===
        moment(sourceEvent.field_end_date_value).format("MMMM Do")
  }

  let totalRequestedHours: number = currentRequest.timeBlocks
    .map((block: BlockType) => {
      return Number(block.hours)
    })
    .reduce((a: number, b: number) => a + b, 0)
  let totalRequestedMinutes: number =
    totalRequestedHours * 60 +
    currentRequest.timeBlocks
      .map((block: BlockType) => Number(block.minutes))
      .reduce((a: number, b: number) => a + b, 0)

  let totalMinutes = totalRequestedMinutes
  totalRequestedHours =
    totalRequestedMinutes >= 60 ? Math.floor(totalRequestedMinutes / 60) : 0
  totalRequestedMinutes = totalRequestedMinutes % 60

  let canProgress = true

  if (currentStep === 1) {
    if (!currentRequest.title) {
      canProgress = false
    }

    if (preApprovalNeeded && !currentRequest.preApprovalFormId) {
      canProgress = false
    }
  }
  if (currentStep === 2) {
    if (!currentRequest.attachments.length) {
      canProgress = false
    }
  }
  if (currentStep === 3) {
    if (
      !totalMinutes ||
      currentRequest.timeBlocks.find((block: BlockType) => !block.categoryId)
    ) {
      canProgress = false
    }
  }
  if (currentStep === 4) {
    if (preApprovalNeeded) {
      if (!responsesToSubmit.length) {
        canProgress = false
      }
      responsesToSubmit.forEach((response: any) => {
        if (response.question.attributes.field_required) {
          if (response.question.type === "paragraph--date_question") {
            if (response.response?.isValid && !response.response.isValid()) {
              canProgress = false
            }
          } else if (!response.response.length) {
            canProgress = false
          }
        }
      })
    }
  }

  function hitMaxTime() {
    setTimeLimitReached(true)
    setTimeout(() => {
      setTimeLimitReached(false)
    }, 1500)
  }

  return (
    <Dialog
      onClose={() => {
        if (!isSubmittingPreApproval) {
          close()
          setTimeout(() => {
            setCurrentStep(1)
          }, 1000)
        }
      }}
      id="external-pd-request-modal"
      className={classNames({ "preview-step": currentStep === 4 })}
      open={open || isSubmittingPreApproval}
    >
      <div
        className={classNames(
          "dialog",
          (currentRequest.isSaving || isSubmittingPreApproval) && "isSaving",
          isRecommended && "recommended",
          currentStep === 4 && "isLastStep"
        )}
      >
        <h2
          style={{
            backgroundColor: sourceEvent ? theme.palette.primary.main : "white",
            backgroundImage: sourceEvent
              ? "url(" + sourceEvent.image + ")"
              : "",
          }}
        >
          <span className="header-title">
            {!isRecommended ? (
              <>External PD Request</>
            ) : (
              <>{currentRequest.title}</>
            )}
          </span>
          <span
            onClick={() => {
              close()
              setTimeout(() => {
                setCurrentStep(1)
              }, 1000)
            }}
            className="icon close"
          />
          {sourceEvent && sourceEvent.image && <div className="gradient" />}
        </h2>

        <div className="modal-body">
          {currentStep === 1 && (
            <>
              {!isRecommended && (
                <section>
                  <header>Title</header>
                  <TextField
                    variant="outlined"
                    required
                    placeholder={"Request Title*"}
                    value={currentRequest.title}
                    onChange={(event) => {
                      dispatch(
                        updatePDRequest({
                          field: "title",
                          value: event.target.value,
                        })
                      )
                    }}
                  />
                </section>
              )}

              <section
                style={{ marginBottom: 0 }}
                className={isRecommended ? "dateTime" : ""}
              >
                <header>Date & Time</header>
                {!isRecommended || !sourceEvent.field_start_date_value ? (
                  <DateRangeSelector
                    startDate={currentRequest.startDate}
                    endDate={currentRequest.endDate}
                    onChange={(params: any) => {
                      dispatch(updatePDRequest(params))
                    }}
                  />
                ) : (
                  <div className="flexRow">
                    <div className="dateBlock">
                      {!isSameDayEvent && (
                        <strong
                          style={{
                            textTransform: "uppercase",
                            opacity: 0.4,
                            fontSize: 10,
                          }}
                        >
                          Start
                        </strong>
                      )}
                      {moment(sourceEvent.field_start_date_value).format(
                        "MMMM Do, YYYY"
                      )}
                      <strong style={{ color: theme.palette.primary.main }}>
                        {moment(sourceEvent.field_start_date_value).format(
                          "h:mma"
                        )}
                        {isSameDayEvent && (
                          <>
                            {" "}
                            -{" "}
                            {moment(sourceEvent.field_end_date_value).format(
                              "h:mma"
                            )}
                          </>
                        )}
                      </strong>
                    </div>

                    {!isSameDayEvent && (
                      <div className="dateBlock">
                        <strong
                          style={{
                            textTransform: "uppercase",
                            opacity: 0.4,
                            fontSize: 10,
                          }}
                        >
                          End
                        </strong>
                        {moment(sourceEvent.field_end_date_value).format(
                          "MMMM Do, YYYY"
                        )}
                        <strong style={{ color: theme.palette.primary.main }}>
                          {moment(sourceEvent.field_end_date_value).format(
                            "h:mma"
                          )}
                        </strong>
                      </div>
                    )}
                  </div>
                )}
              </section>

              {(!sourceEvent ||
                (sourceEvent && sourceEvent.description__value)) && (
                <section className="request-description">
                  <header>Description</header>
                  {!sourceEvent ? (
                    <TextField
                      variant="outlined"
                      sx={{ marginTop: 2 }}
                      multiline
                      value={currentRequest.description
                        .replaceAll("<p>", "")
                        .replaceAll("</p>", "")}
                      onChange={(event) => {
                        dispatch(
                          updatePDRequest({
                            field: "description",
                            value: event.target.value,
                          })
                        )
                      }}
                    />
                  ) : (
                    <SanitizedHTML html={sourceEvent.description__value} />
                  )}
                </section>
              )}
            </>
          )}

          {currentStep === 2 && (
            <>
              <section>
                <header>Attachments</header>
                <span className="helperText">
                  Attach any documents, photos, or files that can confirm your
                  attendance.
                </span>

                <ul className="pd-attachment-list">
                  {currentRequest.attachments.map(
                    (file: any, index: number) => {
                      return (
                        <li key={index}>
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {file.extension !== "png" &&
                            file.extension !== "jpg" &&
                            file.extension !== "jpeg" ? (
                              <span
                                className={classNames("icon", file.extension)}
                              />
                            ) : (
                              <span
                                className={classNames("icon image")}
                                style={{
                                  backgroundImage: "url(" + file.file + ")",
                                }}
                              />
                            )}
                            {file.filename}
                          </div>
                          <DeleteButton
                            onClick={() => {
                              dispatch(deleteAttachment({ index }))
                            }}
                          />
                        </li>
                      )
                    }
                  )}
                </ul>

                <div
                  className="flexRow"
                  style={{ marginTop: 20, justifyContent: "center" }}
                >
                  <FileUpload addFile={addAttachment} />
                </div>
              </section>
            </>
          )}

          {currentStep === 3 && (
            <>
              <section>
                <header>Hours Requested</header>
                <span className="helperText" style={{ marginBottom: 30 }}>
                  Request credit hours up to the maximum of
                  <span
                    className={classNames(
                      "highlight",
                      timeLimitReached && "warning"
                    )}
                  >
                    <strong>
                      {" "}
                      {currentRequest.totalAvailableTime.hours}{" "}
                      {pluralize(
                        "hour",
                        currentRequest.totalAvailableTime.hours
                      )}
                    </strong>
                    {currentRequest.totalAvailableTime.minutes > 0 ? (
                      <strong>
                        {" "}
                        {currentRequest.totalAvailableTime.minutes}{" "}
                        {pluralize(
                          "minute",
                          currentRequest.totalAvailableTime.minutes
                        )}
                        .
                      </strong>
                    ) : (
                      <>.</>
                    )}
                  </span>
                </span>
                <ul className="timeBlockList categories-list">
                  {currentRequest.timeBlocks.map(
                    (timeBlock: BlockType, index: number) => {
                      const blockCategory = categories.data.find(
                        (cat: any) => cat.id === timeBlock.categoryId
                      )
                        ? categories.data.find(
                            (cat: any) => cat.id === timeBlock.categoryId
                          )
                        : null

                      return (
                        <li key={index} className="flexRow">
                          <TextField
                            className="timeBlocks"
                            variant="outlined"
                            type="number"
                            label={!index && "Hours"}
                            value={timeBlock.hours}
                            onChange={(event) => {
                              const hours =
                                Number(event.target.value) >= 0
                                  ? Number(event.target.value)
                                  : Number(timeBlock.hours)

                              const updatedBlock = {
                                hours,
                                minutes: Number(timeBlock.minutes),
                                categoryId: timeBlock.categoryId,
                              }

                              if (
                                Number(timeBlock.hours) <
                                Number(event.target.value)
                              ) {
                                if (
                                  totalRequestedHours <
                                  currentRequest.totalAvailableTime.hours
                                ) {
                                  dispatch(
                                    updateTimeBlock({
                                      index,
                                      value: updatedBlock,
                                    })
                                  )
                                } else {
                                  hitMaxTime()
                                }
                              } else {
                                dispatch(
                                  updateTimeBlock({
                                    index,
                                    value: updatedBlock,
                                  })
                                )
                              }
                            }}
                          />

                          <TextField
                            className="timeBlocks"
                            variant="outlined"
                            type="number"
                            label={!index && "Minutes"}
                            value={timeBlock.minutes}
                            onChange={(event) => {
                              const minutes =
                                event.target.value.length < 3 &&
                                Number(event.target.value) < 60 &&
                                Number(event.target.value) >= 0
                                  ? Number(event.target.value)
                                  : Number(timeBlock.minutes)

                              const updatedBlock = {
                                hours: Number(timeBlock.hours),
                                minutes,
                                categoryId: timeBlock.categoryId,
                              }

                              const totalMinutes =
                                totalRequestedMinutes + totalRequestedHours * 60
                              const avialableMinutes =
                                currentRequest.totalAvailableTime.minutes +
                                currentRequest.totalAvailableTime.hours * 60

                              if (
                                Number(timeBlock.minutes) <
                                Number(event.target.value)
                              ) {
                                if (totalMinutes < avialableMinutes) {
                                  dispatch(
                                    updateTimeBlock({
                                      index,
                                      value: updatedBlock,
                                    })
                                  )
                                } else {
                                  hitMaxTime()
                                }
                              } else {
                                dispatch(
                                  updateTimeBlock({
                                    index,
                                    value: updatedBlock,
                                  })
                                )
                              }
                            }}
                          />

                          <CategorySelect
                            currentValue={blockCategory}
                            onChange={(selected) => {
                              const updatedBlock = {
                                hours: Number(timeBlock.hours),
                                minutes: Number(timeBlock.minutes),
                                categoryId: selected && selected.id,
                              }
                              dispatch(
                                updateTimeBlock({ index, value: updatedBlock })
                              )
                            }}
                            categories={categories}
                            label={!index ? "Category" : ""}
                            allowedLevels={3}
                          />

                          {currentRequest.timeBlocks.length > 1 && (
                            <DeleteButton
                              onClick={() => {
                                dispatch(deleteTimeBlock({ index }))
                              }}
                            />
                          )}
                        </li>
                      )
                    }
                  )}
                </ul>

                <div className="flexRow" style={{ alignItems: "flex-end" }}>
                  <div
                    style={{ flex: 1 }}
                    className={classNames(
                      "capsuleWrapper",
                      timeLimitReached && "warning"
                    )}
                  >
                    <TimeCapsule
                      hours={totalRequestedHours}
                      minutes={totalRequestedMinutes}
                    />
                  </div>

                  <ButtonSmall
                    onClick={() => {
                      dispatch(addTimeBlock())
                    }}
                  >
                    <Plus />
                    <span style={{ paddingLeft: 5 }}>Add Time Category</span>
                  </ButtonSmall>
                </div>
              </section>

              <section>
                <header>Rating</header>
                <span className="helperText">
                  Please rate the event you attended, and provide any feedback
                  below.
                </span>
                <div className="flexRow" style={{ marginTop: -5 }}>
                  {[1, 2, 3, 4, 5].map((value) => {
                    return (
                      <Button
                        key={value}
                        className="star-button"
                        onClick={() =>
                          dispatch(updatePDRequest({ field: "rating", value }))
                        }
                      >
                        <RatingStar selected={currentRequest.rating >= value} />
                      </Button>
                    )
                  })}
                </div>
              </section>

              <section className="xpd-comments">
                <header>Comments</header>
                <TextField
                  variant="outlined"
                  multiline
                  value={currentRequest.comments}
                  onChange={(event) => {
                    dispatch(
                      updatePDRequest({
                        field: "comments",
                        value: event.target.value,
                      })
                    )
                  }}
                />
              </section>
            </>
          )}

          {currentStep === 4 && !preApprovalNeeded && (
            <>
              <LearningBuddyMessage
                message={
                  <>
                    <strong>Does everything look correct? </strong>After
                    clicking <strong>Finish</strong>, a reviewer will take a
                    look at your request as soon as possible.
                  </>
                }
              />
              <ul className="preview">
                <UnapprovedCard
                  isActive={true}
                  preview={true}
                  event={{
                    title: currentRequest.title,
                    field_status: "pending",
                    field_rating_value: currentRequest.rating,
                    field_total_time_value: totalMinutes,
                    field_learner_comment_value: currentRequest.comments,
                    field_start_date_value:
                      moment(currentRequest.startDate).format().split("T")[0] +
                      "T" +
                      moment(currentRequest.startDateTime)
                        .format()
                        .split("T")[1],
                    description__value: currentRequest.description,
                    attachments: currentRequest.attachments,
                    timeBlocks: currentRequest.timeBlocks,
                  }}
                />
              </ul>
            </>
          )}

          {currentStep === 4 &&
            preApprovalNeeded &&
            selectedPreApprovalForm && (
              <>
                <div style={{ marginTop: -35 }}>
                  <LearningBuddyMessage
                    message={
                      <div style={{ marginTop: 5 }}>
                        Answer the following{" "}
                        {pluralize(
                          "question",
                          selectedPreApprovalForm.questions.length
                        )}{" "}
                        and I'll get this to a reviewer for approval. You'll be
                        notified as soon as it's approved.
                      </div>
                    }
                  />
                </div>
                {selectedPreApprovalForm && (
                  <Box
                    style={{
                      margin: "0 -15px",
                    }}
                  >
                    <PreApprovalForm
                      setResponsesToSubmit={setResponsesToSubmit}
                      selectedPreApprovalForm={selectedPreApprovalForm}
                    />
                  </Box>
                )}
              </>
            )}

          {currentStep === 1 &&
            !isRecommended &&
            !currentRequest.id &&
            preApprovalForms.data.length > 0 && (
              <Box
                style={{
                  margin: "10px -20px 0px",
                  padding: "5px 20px",
                }}
              >
                <FormControlLabel
                  style={{ color: "black" }}
                  control={
                    <Checkbox
                      style={{
                        color: theme.palette.secondary.main,
                      }}
                      checked={preApprovalNeeded}
                      onChange={(event) => {
                        dispatch(setPreApprovalNeeded(event.target.checked))
                      }}
                    />
                  }
                  label="I need pre-approval for this event"
                />

                {preApprovalNeeded && preApprovalForms.data.length > 0 && (
                  <>
                    <Box style={{ overflow: "hidden", marginTop: 15 }}>
                      <InlineBuddyMessage message="Select the appropriate pre-approval form from the list below." />
                    </Box>

                    <FormControl
                      id="approval-form-select"
                      sx={{ marginTop: 3 }}
                    >
                      <ul style={{ paddingInline: 2 }}>
                        {preApprovalForms.data.map(
                          (form: PreApprovalFormEntity) => {
                            const isSelected =
                              currentRequest.preApprovalFormId === form.id
                            return (
                              <li
                                className={classNames(
                                  "formSelectItem",
                                  isSelected && "active"
                                )}
                                onClick={(event) => {
                                  dispatch(selectPreApprovalForm(form.id))
                                }}
                                style={{
                                  marginBottom: 10,
                                }}
                              >
                                <Card
                                  sx={{
                                    padding: 2,
                                    backgroundColor: isSelected
                                      ? theme.palette.secondary.main
                                      : "inherit",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span className="icon form" />
                                    <strong style={{ fontSize: 16 }}>
                                      {form.attributes?.label}
                                    </strong>
                                  </Box>

                                  <p
                                    style={{
                                      margin: "5px 0 10px",
                                      fontSize: 14,
                                    }}
                                  >
                                    {form.attributes?.description?.value}
                                  </p>

                                  <Box
                                    sx={{
                                      display: "flex",
                                      alginItems: "center",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 12,
                                        lineHeight: "26px",
                                      }}
                                    >
                                      Estimated Approval Time
                                    </div>
                                    <div
                                      style={{
                                        fontWeight: "bold",
                                        backgroundColor: isSelected
                                          ? "black"
                                          : theme.palette.secondary.main,
                                        display: "block",
                                        color: isSelected ? "white" : "black",
                                        padding: "4px 10px",
                                        borderRadius: 20,
                                        fontSize: 12,
                                        marginLeft: 10,
                                      }}
                                    >
                                      {form.turnaroundTime} Days
                                    </div>
                                  </Box>
                                </Card>
                              </li>
                            )
                          }
                        )}
                      </ul>
                    </FormControl>
                  </>
                )}
              </Box>
            )}
        </div>

        <footer>
          <div
            className="flexRow"
            style={{
              justifyContent: currentStep > 1 ? "space-between" : "center",
            }}
          >
            {currentStep > 1 && (
              <Button
                onClick={() => {
                  if (preApprovalNeeded) {
                    setCurrentStep(1)
                    setResponsesToSubmit([])
                  } else {
                    setCurrentStep(currentStep - 1)
                  }
                }}
                className="button large secondary"
              >
                Back
              </Button>
            )}

            {currentStep < 4 ? (
              <Button
                disabled={!canProgress}
                onClick={() => {
                  if (currentStep === 1 && preApprovalNeeded) {
                    setCurrentStep(4)
                  } else {
                    setCurrentStep(currentStep + 1)
                  }
                }}
                className={"button large secondary"}
              >
                Next
              </Button>
            ) : (
              <ButtonLarge
                style={{
                  pointerEvents: canProgress ? "auto" : "none",
                  opacity: canProgress ? 1 : 0.5,
                }}
                onClick={() => {
                  if (preApprovalNeeded) {
                    setIsSubmittingPreApproval(true)

                    // First save external request
                    dispatch(
                      saveExternalRequest({ currentRequest, preApprovalNeeded })
                    ).then((externalUserEvent) => {
                      // Create user preapproval form
                      dispatch(
                        saveUserPreApprovalForm({
                          currentRequest,
                          responsesToSubmit,
                          externalUserEvent: externalUserEvent.payload,
                        })
                      ).then((response) => {
                        // Refetch requests
                        dispatch(fetchExternalPDRequests({})).then(() => {
                          setIsSubmittingPreApproval(false)
                          close()
                          setCurrentStep(1)
                        })
                      })
                    })
                  } else {
                    dispatch(
                      saveExternalRequest({ currentRequest, preApprovalNeeded })
                    ).then(() => {
                      setIsSubmittingPreApproval(false)
                      close()
                      setCurrentStep(1)
                    })
                  }
                }}
                className="button large secondary"
              >
                {preApprovalNeeded ? <>Submit Pre-Approval</> : <>Finish</>}
              </ButtonLarge>
            )}
          </div>

          {currentStep < 5 && (
            <ProgressBar
              width={currentStep !== 1 ? ((currentStep - 1) / 3) * 100 : 5}
            />
          )}
        </footer>
      </div>
      {(currentRequest.isSaving || isSubmittingPreApproval) && (
        <Loading
          fullPage={true}
          message={
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <strong>Saving request...</strong>
              <strong>Please do not navigate away from this page.</strong>
            </Box>
          }
        />
      )}
    </Dialog>
  )
}

export default RequestCreditModal
